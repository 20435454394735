import React from 'react';
import SEO from '../components/seo';
import { Link } from 'gatsby';

import { BasicPageContain, CopyBlockStyle } from '../utils/copperTypography';


const ThanksPage = () => (
  <BasicPageContain>
    <SEO title="Thanks" />
    <CopyBlockStyle>
      <h3>Copper Compression</h3>
      <h1>Thanks</h1>
      <p className='wide'>Thank you for your message, we will get back to you shortly.</p>
      <Link to="/" className="mt40">Go back to the homepage</Link>
    </CopyBlockStyle>
  </BasicPageContain>
);

export default ThanksPage;
